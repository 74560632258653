import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';
import { stringifyFilters } from '../shared/utility';

export const PatientsRepository = {
  baseUrl: Endpoints.patients,

  list: async function (filters = {}) {
    try {
      const queryString = stringifyFilters({ ...filters });
      const { data } = await http.get(`${this.baseUrl}${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  get: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  create: async function (payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  update: async function (id, payload) {
    try {
      const { data } = await http.patch(`${this.baseUrl}/${id}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  delete: async function (id) {
    try {
      const { data } = await http.delete(`${this.baseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  getDiseases: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${id}/diseases`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  createDisease: async function (id, payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}/${id}/diseases`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  editDisease: async function (id, diseaseId, payload) {
    try {
      const { data } = await http.patch(`${this.baseUrl}/${id}/diseases/${diseaseId}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  deleteDisease: async function (id, diseaseId) {
    try {
      const { data } = await http.delete(`${this.baseUrl}/${id}/diseases/${diseaseId}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  listMedicalRecords: async function (patientId) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${patientId}/medical-records`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  getMedicalRecord: async function (patientId, id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${patientId}/medical-records/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  createMedicalRecords: async function (id, payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}/${id}/medical-records`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  deleteMedicalRecord: async function (id, medicalId) {
    try {
      await http.delete(`${this.baseUrl}/${id}/medical-records/${medicalId}`);
      return true;
    } catch (err) {
      return parseError(err);
    }
  },

  editMedicalRecords: async function (id, medicalId, payload) {
    try {
      const { data } = await http.patch(`${this.baseUrl}/${id}/medical-records/${medicalId}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  getProtocolos: async function (id, filters = {}) {
    try {
      const queryString = stringifyFilters({ ...filters });
      const { data } = await http.get(`${this.baseUrl}/${id}/protocols${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  postProtocols: async function (id, payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}/${id}/protocols`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
