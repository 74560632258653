// hooks
import { useEffect, useState, useContext } from 'react';
import { useToast } from 'hooks/useToast';

// scss
import styles from './Estoque.module.scss';

// contexts
import { LoaderContext } from 'contexts/loader';
import { ClinicIdContext } from 'contexts/clinicId';

// components
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Table from 'components/Table/Table';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { CiExport } from 'react-icons/ci';
import { TbEdit } from 'react-icons/tb';
import { FaEye } from 'react-icons/fa';
import { Grid } from '@mui/material';
import { IoTriangle } from 'react-icons/io5';

// other
import { StockRepository } from 'data/stock';
import { RepositoryPurchaseOrders } from 'data/purchaseorders';
import { differenceInDays, parseISO } from 'date-fns';
import { validateEntradaEstoque } from './validator';
import moment from 'moment';
import TableFooter from 'components/TableFooter/TableFooter';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { ItemsRepository } from 'data/stockitems';

const initialModalState = {
  isOpen: '',
  item: null,
  success: true,
};

const initialData = {
  batch: '',
  entry_date: '',
  expiration_date: '',
  entry_quantity: null,
  balance: 0,
  clinic: null,
  item: null,
  purchase_order: null,
  supplier: null,
};

function Estoque() {
  const toast = useToast();
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [modalState, setModalState] = useState(initialModalState);
  const [purchaseList, setPurchaseList] = useState([]);
  const [items, setItems] = useState([]);
  const [stockItems, setStockItems] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
  const [list, setList] = useState(null);
  const [item, setItem] = useState({});
  const [expandedItem, setExpandedItem] = useState(null);
  const [balanceModal, setBalanceModal] = useState(0);
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await RepositoryPurchaseOrders.getItemFromCatalog(formData?.purchase_order);
      setLoading(false);
      setStockItems(response.results ?? []);
    };
    if (formData?.purchase_order) run();
  }, [formData?.purchase_order]);

  const loadStockList = async () => {
    setLoading(true);
    const response = await StockRepository.list({ clinic: clinicId, item: expandedItem });
    setList(response.results);
    setLoading(false);
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const responseItems = await ItemsRepository.listExpiration(clinicId);
      setItems(responseItems.results ?? []);
      setLoading(false);
    };
    if (clinicId != null) {
      run();
      setFormData({
        ...formData,
        clinic: clinicId,
      })
    }
  }, [clinicId]);
  
  useEffect(() => {
    const run = async () => {
      setLoading(true);
      setList(null);
      setStockItems([ ...items ]);
      loadStockList();
    };
    if (expandedItem != null) {
      run();
    }
  }, [expandedItem]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await StockRepository.get(modalState.item !== null ? modalState.item : '');
      const purchaseResponse = await RepositoryPurchaseOrders.getApprovedOrders({ clinic: clinicId, status: 'approved', limit: 9999 });
      const fornecedoresResponse = await RepositoryPurchaseOrders.getSuppliers(clinicId);
      setFornecedores(fornecedoresResponse.results);
      setItem(response);
      setPurchaseList(purchaseResponse.results ?? []);
      setBalanceModal(response.balance);
      setLoading(false);
    };
    if (modalState.isOpen === 'addEstoque') {
      run();
    }
  }, [modalState.item, modalState.isOpen]);

  const editButton = id => (
    <div className={styles.editButton}>
      <div onClick={() => setModalState({ ...modalState, isOpen: 'viewPedido', item: id })}>
        <FaEye className={styles.pen} color="#15ab72" title="Visualizar Entrada" />
      </div>
      <div onClick={() => setModalState({ ...modalState, isOpen: 'addUnit', item: id })}>
        <TbEdit className={styles.pen} color="#C5A500" title="Editar Entrada" />
      </div>
    </div>
  );

  function onClose() {
    setModalState(initialModalState);
  }

  const handleQtdModal = value => {
    if (value === '+' && balanceModal < item.entry_quantity) {
      setBalanceModal(balanceModal + 1);
    } else if (value === '-') {
      if (balanceModal > 0) {
        setBalanceModal(balanceModal - 1);
      }
    }
  };

  const atualizarQtd = async () => {
    setLoading(true);
    const response = await StockRepository.update(modalState.item, { balance: balanceModal });
    if (response?.error) toast.error(response.error, response.debug);
    if (balanceModal !== item.balance) {
      loadStockList();
    }
    setLoading(false);
  };

  const diasVencimento = (data1, data2) => {
    const difference = differenceInDays(parseISO(data2), parseISO(data1));
    return difference > 1 ? <p>{difference} dias</p> : <p>{difference} dia</p>;
  };

  const handleDateChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChange = event => {
    let value = event.value;
    if (event.name === 'entry_quantity') {
      setFormData({ ...formData, [event.name]: value, balance: parseInt(value) });
    } else setFormData({ ...formData, [event.name]: value });
  };

  const handleSubmit = async type => {
    try {
      await validateEntradaEstoque(formData);
    } catch (err) {
      toast.error(err.message);
      return;
    }
    try {
      await saveData(type);
    } catch (err) {
      toast.error(err.errors?.[0] || err.message);
    }
  };

  const saveData = async type => {
    setLoading(true);
    try {
      const response = await StockRepository.create(formData);
      if (response?.error) {
        toast.error(response?.error);
      } else {
        if (type === 'exit') {
          setModalState(initialModalState);
          loadStockList();
        } else if (type === 'continue') {
          setFormData({
            ...formData,
            item: null,
            batch: '',
            expiration_date: '',
            entry_quantity: '',
            balance: 0,
            supplier: null,
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleExportButtonClick = async () => {
    try {
      const exportedData = await StockRepository.export();
      const blob = new Blob([exportedData], { type: 'application/csv' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'exported_data.csv';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const purchaseListOptions = (purchaseList ?? []).map(item => ({ value: item.id, label: item.id }));
  const stockItemsOptions = (stockItems ?? []).map(item => ({ value: item.id, label: item.name }));
  const fornecedoresOptions = (fornecedores ?? []).map(item => ({ value: item.id, label: item.company_name }));

  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <PageTitle>Estoque</PageTitle>
        <Button
          color="secondary"
          label="+ Entrada Estoque"
          onClick={() => setModalState({ ...modalState, isOpen: 'addEstoque' })}
        />
      </div>
      <div>
       {items.map(item => {
        return <div className={styles.itemsList}>
          <Button
            label={expandedItem === item.id ? '-' : '+'}
            color="secondary"
            className={styles.expandItem}
            onClick={() => {
              if (expandedItem === item.id) {
                setExpandedItem(null);
              } else {
                setExpandedItem(item.id);
              }              
            }}
            size="sm"
          />
          <div>{item.name}</div>
          <div>
            {(item.days_to_expire_alert != null) && (
              <span className={item.days_to_expire_alert > 5 ? styles.warning : styles.danger}>
                {item.days_to_expire_alert > 0 ? `Vencerá em ${item.days_to_expire_alert} dias` : 'Possui item já vencido'}!
              </span>
            )}
          </div>
          <div>
            {(item.current_quantity_alert != null) && (
              <span className={item.current_quantity_alert > 2 ? styles.warning : styles.danger}>
                Há {item.current_quantity_alert} item(ns) em estoque!
              </span>
            )}
          </div>
          <div className={styles.table}>
            {expandedItem === item.id && (
              (list ?? []).length > 0 ? (
                <>
                  <Table
                    tableHeight="50vh"
                    columns={[
                      'Data', 
                      'Cod do Pedido', 
                      'Lote', 
                      'Dias Vencimento', 
                      'Qtd Unidades', 
                      'Qtd Fracionado Por Unidade', 
                      'Saldo de Unidades', 
                      'Ações'
                    ]}
                    lines={list.map(stock => {
                      return [
                        moment(stock.entry_date).format('DD/MM/YYYY'),
                        stock.purchase_order,
                        stock.batch,
                        diasVencimento(stock.entry_date, stock.expiration_date),
                        stock.entry_quantity,
                        item.is_fractional ? item.fractional_unity.toString() : 'N/A',
                        item.is_fractional ? stock.balance.toFixed(2) : stock.balance,
                        editButton(stock.id),
                      ];
                    })}
                  />
                  <TableFooter>
                    <div></div>
                    <Button
                      label="Exportar"
                      icon={<CiExport />}
                      color="secondary"
                      disabled={(list ?? []).length === 0}
                      onClick={handleExportButtonClick}
                    />
                  </TableFooter>
                </>
              ) : (
                <div align="center" style={{ margin: '16px 0' }}>
                  {list == null ? <small>Carregando...</small> : <h4>Nenhum estoque encontrado para esse insumo</h4>}
                </div>
              )
            )}
          </div>
        </div>
       })}
      </div>
      <ButtonVoltar link="/estoque/home" />
      <Modal
        isOpen={modalState.isOpen === 'addUnit'}
        setModalOpen={() => {
          atualizarQtd();
          onClose();
        }}
      >
        <div className={styles.containerAddUnit}>
          <h1>{item.item_name}</h1>
          <div className={styles.qtd}>
            <div className={styles.borderNumber}>
              <span>{balanceModal}</span>
            </div>
            {balanceModal === 1 ? <p>unidade</p> : <p>unidades</p>}
          </div>
          <div className={styles.contBtnAddUnit}>
            <IoTriangle className={styles.btnAddUnit} onClick={() => handleQtdModal('+')}></IoTriangle>
            <IoTriangle className={styles.btnMinusAddUnit} onClick={() => handleQtdModal('-')}></IoTriangle>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalState.isOpen === 'viewPedido'}
        setModalOpen={() => {
          onClose();
        }}
      >
        <div className={styles.containerViewPedido}>
          <h1>Visualizar</h1>
          <div className={styles.contentView}>
            <p>Código do Pedido: {item.purchase_order}</p>
            <p>Data de Entrada: {moment(item.entry_date).format('DD/MM/YYYY')} </p>
            <p>Código do Lote: {item.batch}</p>
            <p>Insumo: {item.item_name}</p>
            <p>Data de Vencimento: {moment(item.expiration_date).format('DD/MM/YYYY')}</p>
            <p> Qtd: {item.entry_quantity}</p>
            <div className={styles.contBtnViewPedido}>
              <div className={styles.saldo}>Saldo: {balanceModal}</div>
              <div>
                <IoTriangle className={styles.btnAdd} onClick={() => handleQtdModal('+')}></IoTriangle>
                <IoTriangle className={styles.btnMinus} onClick={() => handleQtdModal('-')}></IoTriangle>
              </div>
            </div>
          </div>
          <div className={styles.btnViewPedido}>
            <Button color="secondary" label="Salvar" onClick={() => atualizarQtd()} />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalState.isOpen === 'addEstoque'}
        setModalOpen={() => {
          onClose();
          loadStockList();
        }}
      >
        <div>
          <h1>Entrada Estoque</h1>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Autocomplete
                className={styles.consultorio_select_global_select}
                label="Pedido (opcional)"
                options={purchaseListOptions}
                value={purchaseListOptions.find(item => item.value === formData.purchase_order)}
                onChange={(_, e) => handleChange({ name: 'purchase_order', value: e.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                className={styles.consultorio_select_global_select}
                label="Fornecedor (opcional)"
                options={fornecedoresOptions}
                value={fornecedoresOptions.find(item => item.value === formData.supplier)}
                onChange={(_, e) => handleChange({ name: 'supplier', value: e.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                className={styles.consultorio_select_global_select}
                label="Insumo"
                options={stockItemsOptions}
                value={stockItemsOptions.find(item => item.value === formData.item)}
                onChange={(_, e) => handleChange({ name: 'item', value: e.value })}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                label="Lote"
                name="batch"
                type="number"
                value={formData.batch}
                onChange={e => handleChange({ name: e.target.name, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                type="date"
                name="entry_date"
                helper="Data de entrada"
                value={formData.entry_date}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                type="date"
                name="expiration_date"
                helper="Data de vencimento"
                value={formData.expiration_date}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                label="Qtd"
                name="entry_quantity"
                value={formData.entry_quantity}
                onChange={e => handleChange({ name: e.target.name, value: e.target.value })}
                inputProps={{ maxLength: 3 }}
              />
            </Grid>
          </Grid>
          <div className={styles.btnAddEstoque}>
            <Button label="Salvar e fechar" onClick={() => handleSubmit('exit')} />
            <Button label="Salvar e continuar" onClick={() => handleSubmit('continue')} />
          </div>
        </div>
      </Modal>
    </Container>
  );
}

export default Estoque;
