import { SchedulesRepository } from 'data/schedules';
import styles from './ModalAgendar.module.scss';
import { useContext, useEffect, useState } from 'react';
import { UserRepository } from 'data/users';
import Modal from 'components/Modal/Modal';
import Input from 'components/InputIcon/InputIcon';
import TextArea from 'components/TextArea/TextArea';
import { ClinicIdContext } from 'contexts/clinicId';
import { UserHoursRepository } from 'data/usersHour';
import { LoaderContext } from 'contexts/loader';
import { weekdayDescription } from 'shared/utility';
import moment from 'moment';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import Button from 'components/Button/Button';

export default function ModalAgendar({ modal, setModal }) {

  const { setLoading } = useContext(LoaderContext);
  const { clinicId } = useContext(ClinicIdContext);
  const [professionals, setProfessionals] = useState([]);

  const [error, _setError] = useState('');
  const setError = newError => {
    _setError(newError);
    setTimeout(() => _setError(''), 4000);
  }

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      await loadProfessionals(modal.eventData);
      setLoading(false);
    };
    if (modal.open) {
      run();
    }
  }, [modal.patient_procedure_id]);
  
  function handleChange(prop, value) {
    setModal(prevModalState => ({
      ...prevModalState,
      eventData: { ...prevModalState.eventData, [prop]: value },
    }));
  }
  
  const loadProfessionals = async selectedProcedure => {
    const filters = { clinic: clinicId };
    if (selectedProcedure.profile === 'doctor') {
      filters.user_type = '1';
    }
    if (selectedProcedure.profile === 'other' && selectedProcedure.service_type != null) {
      filters.user_type = '7';
      filters.service_type = selectedProcedure.service_type;
    }
    const userResponse = await UserRepository.list(filters);
    setProfessionals(userResponse?.results ?? []);
  }

  const validateHourForProfessional = (professionalDayHours, selectedHour) => {
    if (selectedHour) {
      let hourSplit = selectedHour.toString().split(':');
      if (hourSplit.length > 0) {
        let hour = parseInt(hourSplit[0]);
        const selectedHourInMinutes = parseInt(hourSplit[1]) + hour * 60;

        for (const hours of professionalDayHours) {
          hourSplit = hours.start_time.split(':');
          hour = parseInt(hourSplit[0]);
          const startWorkingInMinutes = parseInt(hourSplit[1]) + hour * 60;
          
          hourSplit = hours.end_time.split(':');
          hour = parseInt(hourSplit[0]);
          const endWorkingInMinutes = parseInt(hourSplit[1]) + hour * 60;
          if (selectedHourInMinutes >= startWorkingInMinutes && selectedHourInMinutes <= endWorkingInMinutes) {
            return true;
          }        
        }
      }
    }
    return false;
  }

  const closeModal = () => {
    setModal({ open: false, patient_procedure_id: null, eventData: null });
  }
  
  const addSchedule = async () => {
    setLoading(true);
    const payload = modal.eventData;
    let schedule_date = payload.schedule_date;
    let weekday;    
    if (typeof schedule_date === 'string' || schedule_date instanceof String) {
      if (schedule_date.includes('T')) {
        schedule_date = schedule_date.split('T')[0] 
      }
      weekday = moment(schedule_date).day();
    } else {
      const moment_schedule_date = moment(schedule_date);
      weekday = moment_schedule_date.day();
      schedule_date = moment_schedule_date.format('YYYY-MM-DD');
    }

    if (weekday == null) {
      setError('Data inválida');
      setLoading(false);
      return;
    }
    weekday = weekdayDescription[weekday];

    const response = await UserHoursRepository.list(payload.user, clinicId);
    let professionalWorkingHours = response?.results ?? [];
    professionalWorkingHours = professionalWorkingHours.filter(hours => hours.day === weekday);
    const ignore = professionalWorkingHours.length === 0; //ignora pois não tem definição, fica a critério do usuário controlar a agenda
    if (!ignore && professionalWorkingHours.length === 0) {
      setError('Este profissional não trabalha no consultório neste dia');
      setLoading(false);
      return;
    }

    if (!ignore && !validateHourForProfessional(professionalWorkingHours, payload.hour)) {
      setError('O profissional não está disponível neste horário');
      setLoading(false);
      return;
    }

    const scheduleResponse = await SchedulesRepository.addSchedule({
      clinic: clinicId,
      user: payload.user,
      patient: payload.patient,
      schedule_date: `${schedule_date}T${payload.hour}`,
      schedule_date_end: payload.hour_end ? `${schedule_date}T${payload.hour_end}` : null,
      description: payload.description,
      patient_procedures: modal.patient_procedure_id,
      status: 'scheduled',
    });

    setLoading(false);

    if (scheduleResponse && !scheduleResponse.error) {
      window.location.reload();
    } else {
      setError('Erro ao adicionar agendamento. Tente novamente mais tarde.');
    }
  }

  const professionalsOptions = professionals.map(p => ({ label: `${p.first_name} ${p.last_name}`, value: p.id }));

  let modalBody = '';
  if (modal.open) {
    modalBody = <>
      <Autocomplete
        label="Profissional"
        value={professionalsOptions.find(item => item.value === modal.eventData.user)}
        options={professionalsOptions}
        onChange={(_, item) => {
          if (item != null) {
            handleChange('user', item.value);
          }
        }}
      />
      <div className={styles.date_container}>
        <span>Data</span>
        <Input
          type="date"
          value={modal.eventData.schedule_date}
          onChange={event => handleChange('schedule_date', event.target.value)}
        />
        <span>Horário (Início)</span>
        <Input
          type="time"
          value={modal.eventData.hour}
          onChange={event => {
            handleChange('hour', event.target.value);
          }}
        />
        <span>Horário (Fim)</span>
        <Input
          type="time"
          value={modal.eventData.hour_end}
          onChange={event => {
            handleChange('hour_end', event.target.value);
          }}
        />
      </div>
      <TextArea
        minRows={3}
        placeholder="Observações"
        value={modal.eventData.description}
        onChange={event => handleChange('description', event.target.value)}
      />
    </>
  }

  return (
    <Modal 
      className={styles.container} 
      isOpen={modal.open} 
      setModalOpen={closeModal}
    >
      <div className={styles.header}>
        <h1 className={styles.title}>Agendar</h1>
      </div>
      <div className={styles.body}>
        { modalBody }
      </div>
      <div className={styles.footer}>
        <Button
          label="Voltar"
          onClick={closeModal}
        />
        <Button
          label="Confirmar"
          className={styles.button_arrived}
          onClick={addSchedule}
        />
      </div>
      {error.length > 0 && <div className={styles.footer_error}>{error}</div>}
    </Modal>
  );
}